//=include components/mobileMenu.js
//=include components/isFilled.js

function invertibleHeader() {
    _header.toggleClass('header--sticky', _window.scrollTop() > 20);
    // $('.mobile-menu').toggleClass('mobile-menu--sticky', _window.scrollTop() > 50);
    _window.on('scroll', function () {
        _header.toggleClass('header--sticky', _window.scrollTop() > 20);
        // $('.mobile-menu').toggleClass('mobile-menu--sticky', _window.scrollTop() > 50);
    });
}

function initCollapsible() {
    _document.on('click', '.js-collapsible-title', function (e) {
        e.preventDefault();

        var _this = $(this),
            _thisContent = _this.siblings('.js-collapsible-content'),
            _thisParent = _this.closest('.js-collapsible-item');

        _thisContent.slideToggle(400, function () {
            _thisParent.toggleClass('js-collapsible-open');
        });

        return false;
    });
}

function App() {
    return {
        mobileMenu: new MobileMenu(),
        isFilled: new IsFilled(),
        init: function () {
            invertibleHeader();
            initCollapsible();
            this.mobileMenu.init();
            this.isFilled.init();
        }
    }
}

_document.ready(function () {
    app = new App();
    app.init();

    autosize($(".textarea"));
    
    if(issetElement('#working_process')){
        lightGallery($('.working_process__video_bg')[0], {
            plugins: [lgVideo],
            download: false,
            counter: false
        });
    }

    _document.on('click', 'a[href*="#"]:not([href="#"])', function (e) {
        var _headerHeight = _header.outerHeight(),
            _thisHref = $(this).attr('href'),
            _sectionId = _thisHref.substr(_thisHref.indexOf('#')),
            _thisSection = $(_sectionId);

        console.log(_headerHeight);
        app.mobileMenu.close();
        if (_thisSection.length > 0) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: _thisSection.offset().top - _headerHeight - 15
            }, 500);
            return false;
        }
    });
});

